import React from "react";
import "../GlobalStyles/globalStyles.css";
import logoSite from "../GlobalImages/logo-set.png";
import menuImage from "../GlobalImages/menu.png";
import profilePic from "../GlobalImages/desin.png";
import Menuu from "@mui/icons-material/Menu";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
} from "react-router-dom";
import "./Navbar.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "../../Redux/Slices/userSlice";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { AccountCircleRounded } from "@mui/icons-material";

const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const firstName = useSelector((state) => state.user.firstName);
  const lastName = useSelector((state) => state.user.lastName);
  const middleName = useSelector((state) => state.user.middleName);
  




  const token = useSelector((state) => state.user.token);
  const localToken = localStorage.getItem("token");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [isHovered, setIsHovered] = useState(false);

  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };
  // Function to handle hover events
  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  const handleClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
    dispatch(removeToken());
   
    // localStorage.removeItem("final_roundedValue");
    localStorage.removeItem("activeTab");
    localStorage.removeItem("isModalOpened");
    localStorage.removeItem("isModalOpenedTwo");
    localStorage.removeItem('appVersion');
    localStorage.removeItem('token')

    // localStorage.clear()

    history.push("/");
    // history.push("/application");
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    history.push("/login");
  };

  const handleNewApp = () => {
    history.push("/application");
  };
  const handleLogout = () => {
    dispatch(removeToken());
    history.push("/");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    if (token || localToken) {
      

      // console.log(
      //   storedFirstName,
      //   storedLastName,
      //   "storedFirstName&storedLastName"
      // );

     
    }
  }, [token, localToken]);

  return (
    <>
      {token || localToken ? (
        <section class="navbar">
        
         <nav class="navbar navbar-expand-lg bg-body-tertiary fixed-top w-100">
  <div class="container">
  <NavLink className="navbar-brand" to="/">
                <img className="logo-set" src={logoSite} alt="" />
              </NavLink>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
    <ul className="navbar-nav">
                    <li className="about nav-item me-1">
                      <NavLink
                        className="nav-link cool-link"
                        to="/application"
                        activeClassName="active"
                        style={{ marginTop: 4 }}
                      >
                        Application
                      </NavLink>
                    </li>

                    <li
                      className="about nav-item me-1"
                      // onClick={() => window.location.reload()}
                    >
                      <NavLink
                        className="nav-link cool-link"
                        to="/status"
                        activeClassName="active"
                      >
                        Status
                      </NavLink>
                    </li>

                    <li className="about nav-item me-1">
                      <NavLink
                        className="nav-link cool-link"
                        to="/support"
                        activeClassName="active"
                      >
                        Support
                      </NavLink>
                    </li>
                    <li>
                    <div class="dropdown">
  <a class="nav-link  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none" , color:"#192c57 ", fontWeight: 400}}>
  <AccountCircleRounded
                        style={{
                          color: isHovered ? "#1a2c57" : "#908c8c",
                          fontSize: 30,
                          marginLeft: 3,
                        }}
                      />{firstName && firstName} {middleName && middleName} {lastName && lastName} 
  </a>
  <ul class="dropdown-menu">
    <li><a class="dropdown-item" href="#" onClick={handleClose}> Logout</a></li>
    
  </ul>
</div>
                    </li>
                    {/* <Button
                     disableRipple
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      onMouseEnter={handleHover}
                      onMouseLeave={handleHover}
                    >
                      Dashboard
                      <span
                        style={{
                          color: isHovered ? "#192c57" : "#192c57",
                          fontWeight: 400,
                          textTransform: "capitalize",
                          textDecoration: "none",
                          fontWeight: "400",
                          fontSize: "18px",
                        }}
                      >
                        {firstName} {lastName}
                      </span>
                      <AccountCircleRounded
                        style={{
                          color: isHovered ? "#1a2c57" : "#908c8c",
                          fontSize: 30,
                          marginLeft: 3,
                        }}
                      />
                    </Button> */}
                    {/* <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleClose}>Logout</MenuItem>
                    </Menu> */}
                  </ul>
      
    </div>
  </div>
</nav>
        
        </section>
      ) : (
        <section class="navbar">
         <nav class="navbar navbar-expand-lg bg-light fixed-top py-2 w-100">
  <div class="container">
  <a class="navbar-brand" href="https://agree.setczone.com">
                <img class="logo-set" src={logoSite} alt="" />
              </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
    <ul class="navbar-nav">
                    <li
                      class="about nav-item active me-1"
                      onClick={handleLogin}
                    >
                      <NavLink
                        className="nav-link cool-link"
                        to="/login"
                        activeClassName="active"
                        style={{ marginTop: 4 }}
                      >
                        Login
                      </NavLink>
                    </li>

                    <li
                      class="about nav-item active me-1"
                      onClick={handleNewApp}
                    >
                      <NavLink
                        className="nav-link cool-link"
                        to="/application"
                        activeClassName="active"
                        style={{ marginTop: 4 }}
                      >
                        Start a new application
                      </NavLink>
                    </li>

                    <li class="about nav-item active"></li>
                  </ul>
    </div>
  </div>
</nav>
        </section>
      )}
    </>
  );
};

export default Navbar;
